import { useDialogQuery } from "@/components/ui/Modal/Dialog/query";

function useModal(id: string, { lock = true, popover = false } = {}) {
  const {
    closeDialog: closeModal,
    open: isOpen,
    openDialog: openModal,
    setOpen
  } = useDialogQuery(id, popover ? "popover" : "modal");

  return {
    closeModal,
    openModal,
    isOpen,
    setOpen,
    toggleModal: () => (isOpen ? closeModal() : openModal())
  };
}

export type IModal = ReturnType<typeof useModal>;

export default useModal;
