"use client";

import useModal from "@/hooks/useModal";
import clsx from "clsx";
import { forwardRef } from "react";
import { type FullButtonProps } from "./Button";
import styles from "./button.module.css";

const ModalButton = forwardRef<HTMLButtonElement, FullButtonProps>(
  (
    {
      color,
      outlined,
      rounded,
      className,
      shadow,
      modal,
      popover,
      full,
      ...props
    },
    ref
  ) => {
    const classes = clsx(
      styles.button,
      color ? styles[color] : null,
      rounded ? styles.rounded : null,
      outlined ? styles.outlined : null,
      shadow ? styles.shadow : null,
      full ? styles.full : null,
      className
    );
    const { toggleModal } = useModal(modal ? modal : "", {
      popover,
      lock: popover ? false : true
    });
    return (
      <button ref={ref} className={classes} onClick={toggleModal} {...props} />
    );
  }
);

ModalButton.displayName = "ModalButton";
export default ModalButton;
