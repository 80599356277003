"use client";

import {
  ForwardedRef,
  Suspense,
  forwardRef,
  type ButtonHTMLAttributes
} from "react";

import clsx from "clsx";
import Link from "next/link";
import { PrefetchLink } from "../PrefetchLink";
import ModalButton from "./ModalButton";
import styles from "./button.module.css";

export type FullButtonProps = ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  mode?: "standard" | "link" | "toggle" | "invert" | "icon" | "unset" | "full";
  outlined?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  full?: boolean;
  modal?: string;
  popover?: boolean;
  isLoading?: boolean;
  href?: string;
  block?: boolean;
  prefetch?: "hover" | "visible";
};

const ButtonContent = ({
  children,
  isLoading
}: Pick<FullButtonProps, "children" | "isLoading">) => {
  return isLoading ? (
    <div className={styles.spinner} />
  ) : (
    <div className={styles.contentWrapper}>
      <div className={styles.label}>{children}</div>
    </div>
  );
};

const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  FullButtonProps
>(
  (
    {
      mode,
      outlined,
      rounded,
      className,
      shadow,
      modal,
      popover,
      isLoading,
      full,
      children,
      disabled,
      href,
      block,
      prefetch,
      ...props
    },
    ref
  ) => {
    const classes = clsx(
      styles.button,
      mode ? styles[mode] : null,
      rounded ? styles.rounded : null,
      outlined ? styles.outlined : null,
      shadow ? styles.shadow : null,
      full ? styles.full : null,
      block ? styles.block : null,
      className
    );

    const disabledState = isLoading || disabled;

    if (modal) {
      return (
        <Suspense>
          <ModalButton
            className={classes}
            modal={modal}
            popover={popover ? true : false}
            disabled={disabledState}
            ref={ref as ForwardedRef<HTMLButtonElement>}
            {...props}
          >
            <ButtonContent isLoading={isLoading}>{children}</ButtonContent>
          </ModalButton>
        </Suspense>
      );
    }

    if (href) {
      const LinkComponent = prefetch === "hover" ? PrefetchLink : Link;
      return (
        <LinkComponent
          className={classes}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          href={href}
        >
          <ButtonContent isLoading={isLoading}>{children}</ButtonContent>
        </LinkComponent>
      );
    }

    return (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        className={classes}
        {...props}
        disabled={disabledState}
      >
        <ButtonContent isLoading={isLoading}>{children}</ButtonContent>
      </button>
    );
  }
);

Button.displayName = "Button";
export default Button;
