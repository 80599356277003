"use client";

import Link from "next/link";
import { useRouter } from "next/navigation";
import {
  ComponentProps,
  MouseEventHandler,
  forwardRef,
  useCallback
} from "react";

type Props = ComponentProps<typeof Link>;

export const PrefetchLink = forwardRef<HTMLAnchorElement, Props>(
  ({ href, onMouseOver, ...props }, ref) => {
    const router = useRouter();

    const handleMouseOver: MouseEventHandler<HTMLAnchorElement> =
      useCallback(() => {
        router.prefetch(href.toString());
      }, [router, href]);
    return (
      <Link
        ref={ref}
        onMouseOver={(e) => {
          handleMouseOver(e);
          onMouseOver?.(e);
        }}
        href={href}
        {...props}
        prefetch={false}
      />
    );
  }
);

PrefetchLink.displayName = "PrefetchLink";
