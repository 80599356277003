import { useQueryState } from "@/hooks/useQueryState";
import { useCallback } from "react";

export const useDialogQuery = (name: string, key = "modal") => {
  const [modalName, setModalName] = useQueryState<string>(key, "", {
    replace: key === "modal",
  });

  const openDialog = useCallback(() => {
    setModalName(name);
  }, [name, setModalName]);

  const closeDialog = useCallback(() => {
    setModalName("");
  }, [setModalName]);

  const setOpen = useCallback(
    (open: boolean) => {
      if (open) {
        openDialog();
      } else {
        closeDialog();
      }
    },
    [closeDialog, openDialog]
  );

  return { openDialog, closeDialog, open: modalName === name, setOpen };
};
