import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const env = createEnv({
  server: {
    NEXT_PREVIEW_TOKEN: z.string(),
    CENTRA_API_SECRET: z.string(),
    CENTRA_INTEGRATION_TOKEN: z.string(),
    CENTRA_INTEGRATION_URL: z.string().url(),
  },
  client: {
    NEXT_PUBLIC_STORYBLOK_TOKEN: z.string(),
    NEXT_PUBLIC_CENTRA_CHECKOUT_API: z.string(),
    NEXT_PUBLIC_ENV: z.string(),
    NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN_PREVIEW: z.string().min(1)
  },
  runtimeEnv: {
    NEXT_PREVIEW_TOKEN: process.env.NEXT_PREVIEW_TOKEN,
    CENTRA_API_SECRET: process.env.CENTRA_API_SECRET,
    CENTRA_INTEGRATION_TOKEN: process.env.CENTRA_INTEGRATION_TOKEN,
    CENTRA_INTEGRATION_URL: process.env.CENTRA_INTEGRATION_URL,
    NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN_PREVIEW: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN_PREVIEW,
    NEXT_PUBLIC_STORYBLOK_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
    NEXT_PUBLIC_CENTRA_CHECKOUT_API:
      process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API,      
    NEXT_PUBLIC_ENV: process.env.NODE_ENV,
  }
});

export default env;
