import { useRouter, useSearchParams } from "next/navigation";
import { useCallback } from "react";
import useHasHydrated from "./useHasHydrated";

export const useQueryState = <T extends string>(
  key: string,
  initialState: T,
  { shallow = true, replace = false } = {}
) => {
  const hasHydrated = useHasHydrated();
  const router = useRouter();
  const searchParams = useSearchParams();

  const val = searchParams.get(key);

  const setValue = useCallback(
    (value: T | ((arg: T) => T)) => {
      const newParams = new URLSearchParams(searchParams.toString());
      const newValue =
        typeof value === "function" ? value((val as T) || initialState) : value;
      if (!newValue) {
        newParams.delete(key);
      } else {
        newParams.set(key, newValue);
      }

      const newUrl = `${window.location.pathname}?${newParams.toString()}`;

      if (shallow) {
        if (replace) {
          window.history.replaceState({}, "", newUrl);
        } else {
          window.history.pushState({}, "", newUrl);
        }
      } else {
        if (replace) {
          router.replace(newUrl);
        } else {
          router.push(newUrl);
        }
      }
    },
    [initialState, key, replace, router, searchParams, shallow, val]
  );

  let value: T | null;
  if (hasHydrated) {
    value = val as T | null;
  } else {
    value = (val as T) ?? initialState;
  }

  return [value, setValue] as const;
};

/**
 * Reactive URLSearchParams hook that allows for updating the URL
 */
export const useQueryParams = ({
  replace = false,
  shallow = true,
} = {}): URLSearchParams => {
  const searchParams = useSearchParams();
  const router = useRouter();

  const getNavigate = useCallback(() => {
    const browser = {
      push: (url: string | URL) =>
        window.history.pushState({}, "", url.toString()),
      replace: (url: string | URL) =>
        window.history.replaceState({}, "", url.toString()),
    };

    const next = {
      push: router.push,
      replace: router.replace,
    };

    const target = shallow ? browser : next;
    return replace ? target.replace : target.push;
  }, [shallow, replace, router.push, router.replace]);

  // Override the set, delete, and clear methods to update the URL
  const set: URLSearchParams["set"] = useCallback(
    (key: string, value: string) => {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set(key, value);
      const navigate = getNavigate();
      navigate(`${window.location.pathname}?${newParams.toString()}`);
    },
    [searchParams, getNavigate]
  );

  const deleteMethod: URLSearchParams["delete"] = useCallback(
    (key: string, value?: string) => {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.delete(key, value);
      const navigate = getNavigate();
      navigate(`${window.location.pathname}?${newParams.toString()}`);
    },
    [searchParams, getNavigate]
  );

  const append: URLSearchParams["append"] = useCallback(
    (key: string, value: string) => {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.append(key, value);
      const navigate = getNavigate();
      navigate(`${window.location.pathname}?${newParams.toString()}`);
    },
    [searchParams, getNavigate]
  );

  return {
    entries: searchParams.entries.bind(searchParams),
    get: searchParams.get.bind(searchParams),
    getAll: searchParams.getAll.bind(searchParams),
    has: searchParams.has.bind(searchParams),
    keys: searchParams.keys.bind(searchParams),
    values: searchParams.values.bind(searchParams),
    sort: searchParams.sort.bind(searchParams),
    size: searchParams.size,
    toString: searchParams.toString.bind(searchParams),
    forEach: searchParams.forEach.bind(searchParams),
    append,
    set,
    delete: deleteMethod,
    [Symbol.iterator]: searchParams[Symbol.iterator].bind(searchParams),
  };
};
